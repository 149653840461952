<!--
 * @Description: 答卷详情
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-19 15:50:16
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-13 17:11:21
-->
<template>
  <div class="center">
    <div class="center-top">
      <a-tabs
        v-model:activeKey="currentTab" 
        type="card"
        @change="handleTabClick"
      >
        <a-tab-pane key="1" :tab="`有效问卷(${questionInfo.validQuestionnaireNum || 0})`">
        </a-tab-pane>
        <a-tab-pane key="2" :tab="`无效问卷(${questionInfo.inValidQuestionnaireNum || 0})`">
        </a-tab-pane>
      </a-tabs>
      <!-- <div ref="validTab" :class="{'active': currentTab === 1}" @click="handleEffectiveClick"> 有效问卷</div>
      <div ref="inValidTab" :class="{'active': currentTab === 2}" @click="handleInvalidClick">无效问卷</div> -->
    </div>
    <RecycleFilter
      @search="handleSearchClick"
      @reset="handleResetClick"
    />
    <a-dropdown class="download">
      <div class="center-middle-download"><span class="iconfont">&#xe612;</span>下载答卷数据</div>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <div @click="handleExport('NUMBER')">序号版本（Excel）</div>
          </a-menu-item>
          <a-menu-item>
            <div @click="handleExport('STRING')">文本版本（Excel）</div>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <div class="center-content">
      <!-- 有效问卷内容 -->
      <template v-if="currentTab === '1'">
        <a-row type="flex" class="table-title">
          <a-col :span="4">答卷编号</a-col>
          <a-col :span="4">答题者IP所在地区</a-col>
          <a-col :span="4">答卷回收渠道</a-col>
          <a-col :span="4">答题所用时间</a-col>
          <a-col :span="4">答卷提交时间</a-col>
          <a-col :span="4">操作</a-col>
        </a-row>
        <a-row 
          type="flex" 
          class="table-row"
          v-for="item in list"
          :key="item.id"
        >
          <a-col :span="4">{{item.numId}}</a-col>
          <a-col :span="4">{{item.ipLocation}}</a-col>
          <a-col :span="4">{{item.collectSource}}</a-col>
          <a-col :span="4">{{item.duration}}秒</a-col>
          <a-col :span="4">{{item.createTime}}</a-col>
          <a-col :span="4" class="table-opera">
            <span @click="handlePreviewClick(item.id)" class="iconfont">&#xe852;</span>
            <span @click="handleValidDeleteClick(item)" class="iconfont">&#xe609;</span>
            <span v-if="item.ifBookmark" @click="handleMarkClick(item.id, item.ifBookmark)" class="iconfont mark" style="font-size: 20px">&#xe638;</span>
            <span v-else @click="handleMarkClick(item.id, item.ifBookmark)" class="iconfont" style="font-size: 20px">&#xe638;</span>
          </a-col>
        </a-row>
      </template>
      <!-- 无效问卷内容 -->
      <template v-else>
        <a-row type="flex" class="table-title">
          <a-col :span="4">答卷编号</a-col>
          <a-col :span="4">答题者IP所在地区</a-col>
          <a-col :span="4">答卷回收渠道</a-col>
          <a-col :span="4">答题所用时间</a-col>
          <a-col :span="4">备注（无效原因）</a-col>
          <a-col :span="4">操作</a-col>
        </a-row>
        <a-row 
          type="flex" 
          class="table-row"
          v-for="item in list"
          :key="item.id"
        >
          <a-col :span="4">{{item.numId}}</a-col>
          <a-col :span="4">{{item.ipLocation}}</a-col>
          <a-col :span="4">{{item.collectSource}}</a-col>
          <a-col :span="4">{{item.duration}}秒</a-col>
          <a-col :span="4">{{item.remark}}</a-col>
          <a-col :span="4" class="table-opera">
            <span @click="handlePreviewClick(item.id)" class="iconfont">&#xe852;</span>
            <span @click="handleRecoverClick(item)" class="iconfont">&#xe60e;</span>
            <!-- <span @click="handleInvalidDeleteClick(item.id)" class="iconfont">&#xe609;</span> -->
            <span v-if="item.ifBookmark" @click="handleMarkClick(item.id, item.ifBookmark)" class="iconfont">&#xe70b;</span>
            <span v-else @click="handleMarkClick(item.id, item.ifBookmark)" class="iconfont">&#xe63f;</span>
          </a-col>
        </a-row>
      </template>
    </div>
    <div class="center-bottom">
      <div class="pager">
        <Pager 
          class="center-bottom-pager" 
          :current="current"
          :total="total"
          :size="size"
          shape="small"
          @onPageChange="onPageChangeEvent"
        />
      </div>
    </div>
  </div>
  <QuestionnaireDetail
    v-if="isShowDetail"
    :questionnaireId="previewQuestionId"
    @close="handleCloseClick"
  /> 
</template>

<script>
import { message } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import Pager from '@/components/common/Pager'
import QuestionnaireDetail from './components/QuestionnaireDetail'
import RecycleFilter from './components/RecycleFilter'
import { ref, reactive, toRefs, inject } from 'vue'
import { getQuestionnaireList, getRecycleSituation, getRecycleSituationFilter, optionQuestionnaire, exportAnalyzeQuestionnaireProto } from '@/api/questionnaire'
import { useRoute, useRouter } from 'vue-router'

// 问卷查询参数
let query = {}
const getRecycleSituationAsync = async (params, query) => {
  // 复制一份
  query = JSON.parse(JSON.stringify(query))
  if (query['source']) {
    params.collectSource = query['source'].source
    delete query['source']
  }
  if (query['time']) {
    Object.assign(params, {
      createTimeStart: query['time'].startTime,
      createTimeEnd: query['time'].endTime
    })
    delete query['time']
  }
  const pairs = []
  // 先处理params字段
  for (const name in params) {
    const value = params[name].toString();
    // 将转换好的键值对,存入容器中
    pairs.push(name + '=' + value);
  }
  // 再处理query
  for (const key in query) {
    const _q = encodeURIComponent(JSON.stringify(query[key]))
    pairs.push('conditions=' + _q)
  }

  let querys = pairs.join('&')
  if (Object.keys(query).length > 0) {
    // 拼接魔法参数
    querys += '&conditions=%7B%7D'
  }

  const result = await getRecycleSituationFilter(querys)
  return result
}
/**
 * 获取问卷回收情况
 */
const getRecycleSituationEffect = (questionnaireProtoId, tabIndex, pageNumber, pageSize) => {
  const questionnaireList = reactive({
    list: [],
    current: 999,
    size: 999,
    total: 10
  })
  const status = tabIndex === '1' ? 'VALID' : 'INVALID'
  const getList = async (questionnaireProtoId, status, page, size) => {
    const params = {
      questionnaireProtoId,
      matchOption: 'ALL',
      status,
      page,
      size
    }
    const result = await getRecycleSituationAsync(params, query)
    if (result.status === 200 && result.data && result.data.code === '00000') {
      const resData = result.data.data
      questionnaireList.list = resData.content
      questionnaireList.current = Number(resData.number)
      questionnaireList.total = Number(resData.totalElements)
      questionnaireList.size = Number(resData.size)
    }
  }
  getList(questionnaireProtoId, status, pageNumber, pageSize)
  const { list, current, total, size } = toRefs(questionnaireList)
  return { list, current, total, size }
}

/**
 * 有效、无效tab点击事件
 */
const clickTabEffect = (currentTab, list, current, total, size) => {
  const route = useRoute()
  const id = route.params.id
  const validTab = ref(null)
  const inValidTab = ref(null)
  const handleTabClick = () => {
    let data
    if (currentTab.value === '1') {
      data = getRecycleSituationEffect(id, currentTab.value, 0, 5)
    } else {
      data = getRecycleSituationEffect(id, currentTab.value, 0, 5)
    }
    list.value = data.list
    total.value = data.total
    current.value = 0
    size.value = 5
  }
  const handleEffectiveClick = () => {
    currentTab.value = '1'
    const data = getRecycleSituationEffect(id, currentTab.value, 0, 5)
    list.value = data.list
    total.value = data.total
    current.value = 0
    size.value = 5
  }
  const handleInvalidClick = () => {
    currentTab.value = '2' 
    const data = getRecycleSituationEffect(id, currentTab.value, 0, 5)
    list.value = data.list
    total.value = data.total
    current.value = 0
    size.value = 5
  }
  return {
    validTab,
    inValidTab,
    handleTabClick,
    handleEffectiveClick,
    handleInvalidClick,
  }
}

/**
 * 问卷明细操作点击事件
 */
const handlClickEffect = (handleTabClick, isShowDetail, emit) => {
  const previewQuestionId = ref()
  const handlePreviewClick = (id) => {
    isShowDetail.value = true
    previewQuestionId.value = id
  }
  // 有效答卷删除
  const handleValidDeleteClick = (item) => {
    const id = item.id
    const remark = item.remark ? item.remark : '无'
    Modal.confirm({
      content: `是否标记为无效问卷？(备注:${remark})`,
      cancelText: '取消',
      onOk() {
        return new Promise((resolve, reject) => {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          del(id, resolve, reject)
        }).catch(() => console.log('errors!'));
      },
      onCancel() {
        Modal.destroyAll();
      },
    })
    async function del (id, resolve, reject) {
      const option = 'MARK_INVALID'
      const result = await optionQuestionnaire(id, option)
      if (result.status === 200 && result.data && result.data.code === '00000') {
        emit('refresh')
        handleTabClick()
        resolve()
      } else {
        reject()
      }
    }
  }
  // 无效答卷删除
  const handleInvalidDeleteClick = (id) => {
    Modal.confirm({
      content: '是否删除无效问卷？',
      cancelText: '取消',
      onOk() {
        return new Promise((resolve, reject) => {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          del(id, resolve, reject)
        }).catch(() => console.log('errors!'));
      },
      onCancel() {
        Modal.destroyAll();
      },
    })
    async function del (id, resolve, reject) {
      const option = 'COMPLETE_DELETE'
      const result = await optionQuestionnaire(id, option)
      if (result.status === 200 && result.data && result.data.code === '00000') {
        emit('refresh')
        handleTabClick()
        resolve()
      } else {
        reject()
      }
    }
  }
  // 撤销答卷
  const handleRecoverClick = (item) => {
    const id = item.id
    const remark = item.remark ? item.remark : '无'
    Modal.confirm({
      content: `是否标记为有效问卷？(备注:${remark})`,
      cancelText: '取消',
      onOk() {
        return new Promise((resolve, reject) => {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          revoke(id, resolve, reject)
        }).catch(() => console.log('errors!'));
      },
      onCancel() {
        Modal.destroyAll();
      },
    })
    async function revoke (id, resolve, reject) {
      const option = 'RECOVERY'
      const result = await optionQuestionnaire(id, option)
      if (result.status === 200 && result.data && result.data.code === '00000') {
        emit('refresh')
        handleTabClick()
        resolve()
      } else {
        reject()
      }
    }
  }
  // 收藏
  const handleMarkClick = async (id, ifBookmark) => {
    const option = ifBookmark ? 'CANCEL_BOOKMARK' : 'BOOKMARK'
    const tips = ifBookmark ? '取消' : ''
    const result = await optionQuestionnaire(id, option)
    if (result.status === 200 && result.data && result.data.code === '00000') {
      message.success(`${tips}收藏成功`)
      handleTabClick()
    } else {
      message.error(`${tips}收藏失败，失败原因：${result.msg}`)
    }
  }
  return {
    previewQuestionId,
    handlePreviewClick,
    handleValidDeleteClick,
    handleInvalidDeleteClick,
    handleMarkClick,
    handleRecoverClick,
  }
}

/**
 * 问卷详情组件触发的事件
 */
const handleQuestionnaireDetailClickEffect = (isShowDetail, emit, handleEffectiveClick) => {
  const handleCloseClick = () => { 
    isShowDetail.value = false 
    emit('refresh')
    handleEffectiveClick()
  }
  return {
    handleCloseClick,
  }
}

/**
 * 翻页操作
 */
const pageChangeEffect = (currentTab, list, current, size, total) => {
  const route = useRoute()
  const onPageChangeEvent = (pageNumber, pageSize) => {
    const id = route.params.id
    const data = getRecycleSituationEffect(id, currentTab.value, pageNumber, pageSize)
    current.value = pageNumber
    size.value = pageSize
    list.value = data.list
    total.value = data.total
  }
  return {
    onPageChangeEvent
  }
}

export default {
  name: 'AnswerDetails',
  emits: ['refresh'],
  props: ['questionInfo'],
  components: {
    Pager,
    QuestionnaireDetail,
    RecycleFilter,
  }, 
  setup (props, { emit }) {
    const route = useRoute()
    const id = route.params.id
    const isShowDetail = ref(false)
    const currentTab = ref('1')
    const { list, current, total, size} = getRecycleSituationEffect(id, currentTab.value, 0, 5)
    const { validTab, inValidTab, handleEffectiveClick, handleInvalidClick, handleTabClick } = clickTabEffect(currentTab, list, current, total, size)
    const { handleCloseClick } = handleQuestionnaireDetailClickEffect(isShowDetail, emit, handleEffectiveClick)
    const { previewQuestionId, handlePreviewClick, handleValidDeleteClick, handleInvalidDeleteClick,
      handleMarkClick, handleRecoverClick } = handlClickEffect(handleTabClick, isShowDetail, emit)
    const { onPageChangeEvent } = pageChangeEffect(currentTab, list, current, size, total)
    function handleExport(exportOption) {
      let url = exportAnalyzeQuestionnaireProto()
      // console.log("从api层获得的url", url);
      let params = {
        questionnaireProtoId: route.params.id || '',
        status: 'VALID',
        page: current.value,
        size: size.value,
        exportOption 
      }
      let pairs = [];
      for (let name in params) {
        let value = params[name].toString();
        // encodeURIComponent()对URL中的键值对进行编码
        name = encodeURIComponent(name);
        value = encodeURIComponent(value);
        // 将转换好的键值对,存入容器中
        pairs.push(name + "=" + value);
      }
      const query = pairs.join("&"); // 将键值对之间用'&'拼接后返回
      url = url + "?" + query;
      window.open(url);
    }
    
    const handleResetClick = async () => {
      await handleSearchClick({
        option: {
          value: 'ALL'
        },
        query: {}
      })
    }

    const handleSearchClick = async (data) => {
      query = data.query
      let params = {
        questionnaireProtoId: route.params.id || '',
        status: currentTab.value === '1' ? 'VALID' : 'INVALID',
        page: current.value,
        size: size.value,
        matchOption: data.option.value,
      }
      const result = await getRecycleSituationAsync(params, query)
      if (result.status === 200 && result.data && result.data.code === '00000') {
        const resData = result.data.data
        list, current, total, size
        list.value = resData.content
        current.value = Number(resData.number)
        total.value = Number(resData.totalElements)
        size.value = Number(resData.size)
      } else {
        message.error('获取数据失败')
      }
    }

    return {
      list,
      current, 
      total, 
      size,
      onPageChangeEvent,
      
      currentTab,
      validTab,
      inValidTab,
      handleEffectiveClick,
      handleInvalidClick,
      handleTabClick,

      handlePreviewClick,
      handleValidDeleteClick,
      handleInvalidDeleteClick,
      handleMarkClick,
      handleRecoverClick,

      isShowDetail,
      previewQuestionId,
      handleCloseClick,
      handleSearchClick,
      handleResetClick,

      handleExport,
    }
  }
}
</script>

<style lang="less" scoped>
.center {
  flex: 1 0 600px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 30px 30px 30px;
  font-size: 14px;
  box-sizing: border-box;
  .mark {
    transform: rotate(45deg);
  }
  .download {
    position: absolute;
    top: 72px;
    right: 30px;
    width: 178px;
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    padding-left: 24px;
    color: #fff;
    background-color: #00A0E9;
    border-radius: 2px;
  }
  .center-top {
    flex: 0 1 60px;
    display: flex;
    justify-content: center;
    height: 60px;
    line-height: 60px;
    color: #333333e5;
    div {
      width: 100%;
      text-align: center;
      cursor:pointer;
    }
    .active {
      color: #f59f34;
      border-bottom: 3px solid #f59a23;
    }
  }
  .center-content {
    flex: 0 0 400px;
    overflow: auto;
    margin-top: 32px;
    border-bottom: 1px solid #e9e9e9;
    font-size: 16px;
    .table-title {
      padding: 12px 0;
      color: #49494a;
      background-color: #f8f9fa;
      text-align: center;
      border-bottom: 1px solid #eff2f5;
    }
    .table-row {
      padding: 10px 0;
      text-align: center;
      border-bottom: 1px solid #eff2f5;
      &:hover {
        background-color: @hoverBackground;
      }
      &:last-child {
        margin-bottom: 60px;
      }
      .table-opera {
        display: flex;
        justify-content: space-evenly;
        span {
          color: #adadad;
          cursor:pointer;
          &:hover {
            color: #F59A23;
          }
        }
      }
    }
  } 
  .center-bottom {
    flex: 0 0 30px;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;
    font-size: 14px;
    .total {
      margin-right: 10px;
      padding-top: 4px;
    }
    .pager {
      
    }
  }
}
</style>
<style lang="less">
.recycle-detail {
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #00A0E9!important;
    font-weight: 500;
    border-top: 1px solid #f0f0f0 !important;
    border-left: 1px solid #f0f0f0 !important;
    border-right: 1px solid #f0f0f0 !important;
    border-bottom: none !important;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #00A0E9;
  }
  .ant-tabs-ink-bar {
    background-color: #00A0E9;
  }
  .ant-tabs-nav .ant-tabs-tab {
    color: #636363;
    background-color: #fff !important;
    font-size: 20px;
    font-weight: 100;
    padding: 0 50px !important;
  }
  .ant-tabs.ant-tabs-card {
    .ant-tabs-nav .ant-tabs-tab {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid #f0f0f0;
    }
  }
}
</style>
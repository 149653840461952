/*
 * @Description: 回收过滤操作
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-08 11:40:09
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-14 15:07:26
 */
import { message } from 'ant-design-vue'
import { ref, reactive  } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getQuestionnaireById } from '@/api/questionnaire'

const OPTION_LABEL = {
  ALL: '全部',
  ANY: '任一'
}

const BASE_OPTIONS = [
  {
    title: '请选择筛选条件',
    questionId: 'none',
    questionType: 'none',
    choices: [
      {
        text: '请选择筛选条件'
      }
    ]
  },
  {
    title: '答卷回收渠道',
    questionId: 'source',
    questionType: 'source',
    choices: [
      {
        text: '请选择筛选条件'
      },
      {
        text: 'PC'
      },
      {
        text: 'WAP'
      }
    ]
  }, 
  {
    title: '答卷提交时间',
    questionId: 'time',
    questionType: 'time',
    choices: [
      {
        text: '请选择筛选条件'
      }
    ]
  }
]

function recycleFilterEffect(emit) {
  const route = useRoute()
  const store = useStore()
  const activeKey = ref(['0'])
  const option = ref('ALL')
  const choicesList = reactive([])
  const optionsList = ref([])
  const id = route.params.id

  const initOptionsAsync = async () => {
    const result = await getQuestionnaireById(id)
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      const questions = BASE_OPTIONS.slice()
      let sort = 1
      for (const question of result.data.data.questions) {
        question.sort = sort++
        if (question.titleCSS) {
          // 处理富文本标题
          question.title = JSON.parse(question.title)
        } else {
          question.title = question.title
        }
        // 处理矩阵题目
        if (question.questionType.indexOf('MATRIX') !==  -1) {
          question.choices =[{ text: '请选择筛选条件' }]
          questions.push(question)
          let subSort = 1
          let index = 0
          question.rowItems.forEach((item) => {
            questions.push({
              questionId: question.questionId,
              index: index++,
              title: `${sort - 1}.${subSort++}${item.text}`,
              questionType: question.questionType,
              choices: [{ text: '请选择筛选条件' }].concat(question.columnItems),
              type: 'sub'
            })
          })
          question.rowItems.unshift({ text: '请选择筛选条件' })
        // 过滤掉简答题和时间题
        } else if (question.questionType.indexOf('SHORT') === -1 && question.questionType.indexOf('DATE') === -1) {
          if (!question.choices) {
            question.choices = []
          }
          question.choices.unshift({ text: '请选择筛选条件' })
          questions.push(question)
        }
      }
      optionsList.value = questions

      // store初始化
      if (store.state.filterChoices.length > 0) {
        choicesList.push.apply(choicesList, store.state.filterChoices)
        search()
      }
    } else {
      message.error('请求答卷失败，失败原因：' + result.data.msg)
    }
  }

  const addChoicesList = () => {
    choicesList.push({
      value: 0,
      subValue: 0,
    })
    // 保存选项
    store.commit('changeFilterChoices', choicesList.slice())
  }

  const delChoicesList = (index) => {
    choicesList.splice(index, 1)
    // 保存选项
    store.commit('changeFilterChoices', choicesList.slice())
  }

  /**
   * 编辑过滤选项列表
   * @param {integer} index 第几行
   * @param {string} type [main, sub] 主过滤还是副过滤
   * @param {string} value 修改的值
   */
  const editChoicesList = (index, type, value) => {
    if (type === 'main') {
      const option = optionsList.value[value]
      // 如果是矩阵且不是行标题,则过滤
      if (option.questionType && option.questionType.indexOf('MATRIX') > -1 && option.type !== 'sub') {
        return
      }
      // 重置副选项
      choicesList[index].subValue = 0
      // 时间选项
      if (option.title === '答卷提交时间') {
        choicesList[index].subValue = []
      }
      choicesList[index].value = value
    } else {
      choicesList[index].subValue = value
    }
    // 保存选项
    store.commit('changeFilterChoices', choicesList.slice())
  }

  const resetChoicesList = () => {
    for (const index in choicesList) {
      choicesList[index].value = 0
      choicesList[index].subValue = 0
    }
    // 保存选项
    store.commit('changeFilterChoices', choicesList.slice())
    emit('reset')
  }

  const buildQuery = () => {
    const query = {}
    for (let index = 0; index < choicesList.length; index++) {
      const choices = choicesList[index]
      // 选项为0，过滤
      if (choices.value === '0' || choices.subValue === '0') break
      const condition = optionsList.value[choices.value]
      const { questionId, questionType } = condition
      if (!query[questionId]) {
        query[questionId] = {
          questionId,
        }
      }
      if (questionType.indexOf('MATRIX') !== -1) {
        if (!query[questionId].questionType) {
          Object.assign(query[questionId], {
            questionType
          })
        }
        if (!query[questionId].tableCheck) {
          query[questionId].tableCheck = {}
        }
        const rowKey = (condition.index).toString()
        // 选项多加了1,这里需要减1
        const colKey = (parseInt(choices.subValue) - 1).toString()
        if (!query[questionId].tableCheck[rowKey]) {
          query[questionId].tableCheck[rowKey] = {}
        }
        query[questionId].tableCheck[rowKey][colKey] = true
      } else if (questionType.indexOf('source') === -1 && questionType.indexOf('time') === -1) {
        // 先设置成object，后面再进行过滤
        if (!query[questionId].choices) {
          query[questionId].choices = {}
        }
        query[questionId].choices[condition.choices[choices.subValue].choiceId] = 1
      } else if (questionType.indexOf('source') !== -1) {
        // 处理source
        query[questionId].source = condition.choices[choices.subValue].text
      } else {
        // 处理time
        Object.assign(query[questionId], {
          startTime: choices.subValue[0].format('YYYY-MM-DD'),
          endTime: choices.subValue[1].format('YYYY-MM-DD')
        })
      }
    }
    // 将chioces转化为数组
    for (const id in query) {
      const item = query[id]
      if (item.choices) {
        const choices = item.choices
        const array = []
        for (const key in choices) {
          array.push({
            choiceId: key
          })
        }
        item.choices = array
      }
    }
    return query
  }

  const search = () => {
    const query = buildQuery()
    emit('search', {
      option,
      query,
    })
  }

  return {
    OPTION_LABEL, BASE_OPTIONS,
    activeKey, option, choicesList, optionsList,
    initOptionsAsync, addChoicesList, delChoicesList, editChoicesList, resetChoicesList, buildQuery, search
  }
}

export default recycleFilterEffect

<!--
 * @Description: 回收筛选
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-14 16:16:40
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-13 15:34:52
-->
<template>
  <div class="filter">
    <a-collapse class="collapse" v-model:activeKey="activeKey">
      <a-collapse-panel key="1" header="答卷筛选">
        <div class="triangle-out"></div>
        <div class="triangle-in"></div>
        <ul>
          <li class="header" style="line-height: 38px;">
            <p style="padding-right: 5px">显示以下符合</p>
            <a-dropdown 
              class="opiton"
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <div class="option-item">{{ OPTION_LABEL[option] }}<span class="iconfont">&#xe665;</span></div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <p @click="option = 'ALL'">全部</p>
                  </a-menu-item>
                  <a-menu-item>
                    <p @click="option = 'ANY'">任一</p>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <p style="padding-left: 5px">条件的答卷</p>
          </li>
          <li class="content" v-for="(item, index) in choicesList" :key="index">
            <div>
              <a-dropdown
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
              >
              <div class="filter-item">
                <p v-if="optionsList[item.value].sort">{{ optionsList[item.value].sort }}.</p>
                <RichTitle :title="optionsList[item.value].title" />
                <span class="iconfont">&#xe665;</span>
              </div>
                <template #overlay>
                  <a-menu>
                    <div v-for="key in Object.keys(optionsList)" :key="key" @click="editChoicesList(index, 'main', key)">
                      <a-menu-item style="display: flex" :disabled="optionsList[key].questionType && optionsList[key].questionType.indexOf('MATRIX') > -1 && optionsList[key].type !== 'sub'">
                        <p v-if="optionsList[key].sort">{{ optionsList[key].sort }}.</p>
                        <RichTitle :title="optionsList[key].title" />
                      </a-menu-item>
                      <a-menu-divider
                        v-if="optionsList[key].questionType && optionsList[key].questionType.indexOf('MATRIX') > -1 && optionsList[key].type !== 'sub'"
                      />
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <div style="width: 50px;"></div>
            <div style="display: flex;">
              <a-range-picker v-if="optionsList[item.value].title === '答卷提交时间'" v-model:value="choicesList[index].subValue" />
              <a-dropdown
                v-else
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
              >
                <div class="filter-item">{{ optionsList[item.value].choices[item.subValue].text }}<span class="iconfont">&#xe665;</span></div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="key in Object.keys(optionsList[item.value].choices)" :key="key"  @click="editChoicesList(index, 'sub', key)">
                      <p>{{ optionsList[item.value].choices[key].text }}</p>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <span class="close iconfont" @click="delChoicesList(index)">&#xe6ce;</span>
            </div>
          </li>
          <li class="add">
            <a-button class="button" type="dashed" @click="addChoicesList()">
              <template #icon>
                <span class="iconfont">+</span>
              </template>
            </a-button>
          </li>
          <li class="bottom">
            <a-button class="reset button" style="margin-right: 16px;" @click="resetChoicesList">重置</a-button>
            <a-button class="search button" style="margin-left: 16px;" type="primary" @click="search">查询</a-button>
          </li>
        </ul>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import recycleFilterEffect from '../effects/recycleFilterEffect'
import RichTitle from '../../../../../components/question/components/richTitle'

export default {
  emits: ['search', 'reset'],
  components: {
    RichTitle
  },
  setup (props, { emit }) {

    const {OPTION_LABEL,
      activeKey, option, choicesList, optionsList,
      initOptionsAsync, addChoicesList, delChoicesList, editChoicesList, resetChoicesList, buildQuery, search
    } = recycleFilterEffect(emit)

    onMounted(initOptionsAsync)
    
    return {
      OPTION_LABEL,
      optionsList,

      activeKey,
      option,
      choicesList,
      addChoicesList,
      delChoicesList,
      editChoicesList,
      resetChoicesList,
      search
    }
  }
}
</script>

<style lang="less" scoped>
.filter {
   .triangle-in {
    position: absolute;
    top: -10px;
    left: 34px;
    width: 0;
    height: 0;
    z-index: 1;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #fcfcfc;
  }
  .triangle-out {
    position: absolute;
    top: -12px;
    left: 33px;
    width: 0;
    height: 0;
    z-index: 1;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #E1E1E8;
  }
  .collapse {
    background: #fff;
    overflow: initial;
    .ant-collapse-item {
      border-bottom: none;
      ::v-deep(.ant-collapse-header) {
        font-size: 20px;
        font-weight: 100;
      }
    }
    ::v-deep(.ant-collapse-content) {
      position: relative;
      overflow: initial;
      background-color: rgba(245, 245, 245, 0.3);
      border: 1px solid #E1E1E8;
    }
    .ant-collapse-item-active {
      ::v-deep(.ant-collapse-header) {
        color: #00A0E9;
        margin-bottom: 5px;
      }
    }
    // ::v-deep(.ant-collapse-content-box) {
    //   height: 150px;
    //   overflow-y: auto;
    // }
  }
  .iconfont {
    color: #E1E1E8;
  }
  .content {
    padding: 5px 0;
  }
  li {
    display: flex;
    line-height: 26px;
    font-size: 16px;
    .opiton {
      position: relative;
      width: 129px;
      height: 41px;
      background: #FFFFFF;
      border: 1px solid #E1E1E8;
      padding-left: 10px;
      .iconfont {
        position: absolute;
        right: 10px;
        color: #E1E1E8;
      }
    }
    .filter-item {
      position: relative;
      display: flex;
      width: 385px;
      cursor: pointer;
      color: #7F7F88;
      border-bottom: 1px solid #E1E1E8;
      &:hover {
        color: #00A0E9;
      }
      .iconfont {
        position: absolute;
        right: 5px;
      }
    }
    .close {
      line-height: 24px;
      padding-left: 10px;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .add {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .button {
      width: 36px;
      height: 36px;
      .iconfont {
        font-size: 24px;
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .reset {
      color: #D3D3DD;
      border: 1px solid #D3D3DD;
    }
    .search { 
      color: #fff;
      background-color: #00A0E9;
    }

    .button {
      width: 108px;
      height: 36px;
      font-size: 20px;
    }
  }
}
</style>